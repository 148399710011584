import React from 'react';
import { graphql } from 'gatsby';

import Footer from '../../../components/Footer';
import Header from '../../../components/Header';
import Layout from '../../../components/atoms/layout';
import PageTitle from '../../../components/PageTitle';
import { Question } from '../../../template/legal/styles';
import TermOfUseProps from '../../../interface/template';
import { Text2, Text3 } from '../../../components/atoms/body';
import withI18next from '../../../i18n/TemplateI18n';

const TermOfUse: React.SFC<any> = (props: TermOfUseProps) => {
  const { pageContext } = props;
  const { pageName } = pageContext;

  return (
    <>
      <Header pageName={pageName} showBackground>
        <PageTitle subtitle="" title="Term Of Use" />
      </Header>
      <Layout>
        <Question>
          <Text3 as="p">Welkom op de Website van Ironhack </Text3>
          <Text3 as="p">Datum van inwerkingtreding: (aangeven)</Text3>
          <Text3 as="p">
            Welkom bij Ironhack. Bedankt voor uw bezoek, en veel plezier op onze
            website!
          </Text3>
          <Text3 as="p">
            Ironhack, Inc. ( "<strong>Ironhack</strong>", "<strong>wij</strong>
            ", <strong>"ons</strong>" of <strong>"onze</strong>") is een
            internationale aanbieder van Web Development en UX/UI Design
            cursussen, met campussen in verschillende delen van de wereld. Deze
            gebruiksvoorwaarden (de <strong>"Voorwaarden</strong>") zijn van
            toepassing op de website van Ironhack,{' '}
            <a href="https://www.ironhack.com/nl">https://www.ironhack.com</a>,
            de locaties van Ironhack wereldwijd en zijn filialen en op de
            aangesloten instellingen. Deze voorwaarden vormen een wettelijke
            overeenkomst tussen u (<strong>"u</strong>",<strong>"uw</strong>")
            en ons; hierin is het gebruik geregeld van alle teksten, gegevens,
            informatie, software, afbeeldingen, foto's en andere zaken (hierna
            te noemen "<strong>Materialen</strong>") die wij of onze filialen en
            aangesloten instellingen aan u ter beschikking stellen, evenals van
            alle diensten (de <strong>"Diensten</strong>") die wij aanbieden op
            onze website (al het bovenstaande in deze voorwaarden verder te
            noemen: de "<strong>Website</strong>").
          </Text3>
          <Text3 as="p">
            LEES DEZE VOORWAARDEN ZORGVULDIG DOOR VOORDAT U OP DEZE WEBSITE
            VERDER BROWSET. MET HET GEBRUIK VAN DEZE WEBSITE GEEFT U AAN DAT U
            DEZE VOORWAARDEN HEEFT GELEZEN EN GEACCEPTEERD. U MAG DEZE WEBSITE
            NIET GEBRUIKEN ALS U DEZE VOORWAARDEN NIET ACCEPTEERT. ALS U NIET
            AKKOORD GAAT, GEBRUIK DEZE WEBSITE DAN NIET.
          </Text3>
          <Text3 as="p">
            NOOT: DEZE VOORWAARDEN BEVATTEN EEN BEPALING OVER
            GESCHILLENBESLECHTING EN ARBITRAGE, MET INBEGRIP VAN EEN VERKLARING
            VAN AFSTAND VAN COLLECTIEVE RECHTSVORDERINGEN IN VERBAND MET UW
            RECHTEN UIT HOOFDE VAN DEZE VOORWAARDEN EN MET BETREKKING TOT ELK
            MOGELIJK GESCHIL DAT U MET DE ONDERNEMING ZOU KUNNEN HEBBEN. ZOALS
            HIERONDER UITEENGEZET, KUNT U AFZIEN VAN BINDENDE INDIVIDUELE
            ARBITRAGE EN VAN HET AFZIEN VAN COLLECTIEVE VORDERINGEN.
          </Text3>
          <Text3 as="p">
            Ironhack behoudt zich het recht voor om delen van deze Voorwaarden
            naar eigen goeddunken op elk moment te wijzigen, aan te passen, toe
            te voegen of te verwijderen. Controleer deze voorwaarden regelmatig.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">WIJZIGINGEN</Text2>
          <Text3 as="p">
            Wij kunnen, geheel naar eigen goeddunken en zonder voorafgaande
            kennisgeving de materialen en diensten die wij aanbieden wijzigen,
            of besluiten om deze Website te veranderen, te staken of te
            beëindigen. Van tijd tot tijd kunnen wij ook bepalingen uit deze
            Voorwaarden wijzigen, bijwerken, toevoegen of schrappen
            (gezamenlijk: "wijzigingen"). Duidelijkheid komt iedereen ten goede,
            dus we beloven u op de hoogte te zullen stellen van eventuele
            wijzigingen in deze Voorwaarden, en ze op deze Website te zullen
            publiceren.
          </Text3>
          <Text3 as="p">
            Indien u niet akkoord gaat met onze wijzigingen, is het enige wat u
            kunt doen, stoppen met het gebruik van deze Website. Als u na
            kennisgeving van dergelijke wijzigingen deze Website blijft
            gebruiken, geeft u daarmee aan dat u deze erkent en aanvaardt en dat
            u instemt met het bindende karakter daarvan. Houd er ook rekening
            mee dat deze Voorwaarden kunnen worden vervangen door bepaalde
            kennisgevingen of juridische bepalingen die uitdrukkelijk kunnen
            worden geplaatst op concrete pagina's van deze Website. Dergelijke
            uitdrukkelijk geplaatste kennisgevingen of bepalingen zullen deel
            uit komen te maken van deze Voorwaarden en in de plaats komen van
            nader aangewezen bestaande bepalingen hierin.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">ALGEMEEN GEBRUIK</Text2>
          <Text3 as="p">
            Door deze website te gebruiken, geeft u aan dat u ten minste 18 jaar
            oud bent. Als u nog geen 18 jaar oud bent, moet u toestemming hebben
            van een volwassene om deze Website te gebruiken en de Voorwaarden
            ervan te accepteren; die volwassene moet een ouder of wettelijke
            voogd zijn, die de aansprakelijkheid aanvaardt voor uw gebruik van
            deze Website.
          </Text3>
          <Text3 as="p">
            Wij nodigen u uit om deze website te gebruiken voor persoonlijke
            consumentendoeleinden (<strong>"Toegestane Doeleinden</strong>").
            Veel plezier!
          </Text3>
          <Text3 as="p">
            Onder deze Voorwaarden verlenen wij u hierbij een beperkte,
            persoonlijke, niet-exclusieve en niet-overdraagbare licentie voor
            het gebruik en de weergave van de Materialen. Uw recht om het
            materiaal te gebruiken is gebonden aan de naleving door u van deze
            Voorwaarden. U heeft geen enkel ander recht op deze Website noch op
            enig Materiaal daarvan, en u mag op deze Website of in het Materiaal
            niets wijzigen, bewerken, kopiëren, reproduceren, verwerken tot
            afgeleide producten, onderwerpen aan reverse-engineering, aanpassen,
            verbeteren of gebruiken voor enige vorm van exploitatie. Als u
            kopieën maakt van een onderdeel van deze website tijdens uw
            activiteit voor één van de Toegestane Doeleinden, raden wij u aan om
            kopieën te maken van al onze auteursrechten en andere
            eigendomsrechten zoals deze op de Website zijn gepubliceerd.
          </Text3>
          <Text3 as="p">
            Indien u een van deze Voorwaarden niet naleeft, vervalt de boven
            genoemde licentie automatisch en moet u onmiddellijk overgaan tot
            vernietiging van alle gedownloade of uitgeprinte materialen (en alle
            kopieën daarvan).
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">GEBRUIK VAN DEZE WEBSITE EN VAN ONZE DIENSTEN</Text2>
          <Text3 as="p">
            Dank u wel voor uw bezoek aan onze website. Onze deur staat wijd
            voor u open, om vrijblijvend en zonder registratie een kijkje te
            nemen.
          </Text3>
          <Text3 as="p">
            Om toegang te krijgen tot bepaalde diensten, onze instructeurs of
            specifiek cursusmateriaal, moet u zich echter eerst registreren en
            moet u vervolgens worden toegelaten tot een van onze Web Development
            of UX/UI Design Bootcamps of tot onze parttime trainingsprogramma's
            (<strong>"Ironhack Bootcamp</strong>").
          </Text3>
          <Text3 as="p">
            Op bepaalde diensten of specifieke onderdelen en functies van de
            Website kunnen aanvullende bepalingen en voorwaarden van toepassing
            zijn. Door u te registreren voor onze diensten gaat u met dergelijke
            bepalingen en voorwaarden akkoord.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">INSCHRIJVING IRONHACK</Text2>
          <Text3 as="p">
            Als u zich wilt registreren voor een Ironhack Bootcamp, dient u ons,
            na het aanklikken van Apply now (Registreren) op deze website, de
            volgende informatie toe te sturen:&nbsp;
          </Text3>
          <Text3 as="p">
            <ul>
              <li>voor- en achternaam;</li>
              <li>telefoonnummer;</li>
              <li>een actief e-mailadres;</li>
              <li>geslacht;</li>
              <li>kenmerk; en</li>
              <li>
                een korte beschrijving van waarom u geïnteresseerd bent in
                Ironhack.
              </li>
            </ul>
            Tijdens het registratieproces kunt u ook aanvullende, optionele
            informatie verstrekken, zodat wij u bij uw bezoek aan de Website een
            meer gepersonaliseerde ervaring kunnen bieden, maar dat laten we
            geheel aan u over. Nadat u de gevraagde informatie hebt ingediend,
            zullen wij bepalen of we uw registratie al dan niet kunnen
            accepteren. Indien goedkeuring wordt verleend, ontvangt u een e-mail
            met de stappen die nodig zijn om het registratieproces te voltooien.
            Als u de hierboven vermelde informatie niet levert, kunnen wij uw
            registratieformulier niet verwerken, noch u op de meest geschikte
            manier onze diensten aanbieden.
          </Text3>
          <Text3 as="p">
            Bij bezoek aan de Website bent u verantwoordelijk voor de naleving
            van deze voorwaarden. De beschikbaarheid en het onderhoud van de
            vereiste technische apparatuur en middelen voor de toegang tot en
            het gebruik van de Website komen, net als de desbetreffende kosten,
            voor uw eigen rekening. Ook bent u zelf aansprakelijk voor de
            vertrouwelijkheid van alle informatie die u ons verstrekt bij uw
            registratie bij Ironhack Bootcamp. Zodra u denkt dat uw persoonlijke
            informatie of de veiligheid met betrekking tot deze Website op
            enigerlei wijze is geschonden, dient u ons daarvan onmiddellijk op
            de hoogte te stellen. Als u meer informatie wenst, bezoek dan de
            pagina{' '}
            <a href="https://www.ironhack.com/nl/wetgeving/juridische-kennisgeving">
              Privacy policy
            </a>
            .
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">ELEKTRONISCHE COMMUNICATIE</Text2>
          <Text3 as="p">
            Door deze website te gebruiken, gaat u akkoord met het ontvangen van
            elektronische mededelingen van ons, met inbegrip van
            e-mailberichten. Deze elektronische mededelingen kunnen onder andere
            kennisgevingen bevatten van toepasselijke vergoedingen en kosten,
            transactiegegevens en andere informatie met betrekking tot de
            Website of onze cursussen. Deze elektronische mededelingen zijn
            onderdeel van uw relatie met ons. U gaat ermee akkoord dat met de
            toezending langs elektronische weg van elke kennisgeving,
            overeenkomst, bekendmaking en andere communicatie is voldaan aan
            alle wettelijke vereisten aangaande de communicatie, inclusief de
            vereiste van schriftelijke kennisgeving.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">PRIVACYBESCHERMING</Text2>
          <Text3 as="p">
            Wij respecteren de informatie die u ons verstrekt en willen ons
            ervan verzekeren dat u precies weet hoe wij deze informatie
            gebruiken. Lees ons <strong>"Privacybeleid</strong>", waarin alles
            wordt uitgelegd. Met het gebruik van deze website accepteert en
            erkent u dat overdrachten via het internet nooit absoluut veilig of
            privé zijn. Houd er altijd rekening mee dat alle informatie die via
            of naar de Website wordt verzonden, door derden kan worden
            onderschept en gelezen, zelfs wanneer deze gecodeerd is.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">LINKS NAAR SITES VAN DERDEN</Text2>
          <Text3 as="p">
            U wilt ons misschien volgen, met ons in contact blijven via sociale
            netwerken, of doorbrowsen naar andere webpagina's waarnaar wordt
            verwezen op onze blog of website. Wij denken dat onze links nuttig
            zijn en soms bieden wij op deze Website links aan naar sites van
            derden. Als u deze links aanklikt, verlaat u onze Website. Wij zijn
            niet verplicht tot het checken van websites van derden die u vanaf
            onze Website bezoekt, wij hebben geen controle over websites van
            derden en wij zijn voor geen enkele website van derden (noch voor de
            daarop beschikbare producten, diensten of content) verantwoordelijk.
            We staan daarom op geen enkele wijze garant en treden geenszins op
            als vertegenwoordiger voor dergelijke websites van derden, noch voor
            de informatie, content, software, producten, diensten of materialen
            die op deze websites beschikbaar zijn, noch voor de resultaten die
            met het gebruik daarvan bereikt kunnen worden. Als u besluit om een
            van de websites die vanaf onze Website zijn gelinkt te bezoeken,
            doet u dit op eigen risico en bent u gehouden aan het privacybeleid
            en de algemene voorwaarden van dergelijke websites van derden.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">VERZENDING</Text2>
          <Text3 as="p">
            Bepaalde onderdelen van deze website (zoals de commentaren in onze
            blog) bieden u de mogelijkheid om uw mening te geven, of ons te
            verzoeken om meer informatie of gegevens, of om tekst of
            boodschappen en ander materiaal te versturen (
            <strong>"Gebruikersbericht</strong>"). U gaat ermee akkoord dat u
            als enige verantwoordelijk bent voor al uw Gebruikersberichten en
            dat dergelijke Gebruikersberichten gelden als niet-vertrouwelijk en
            dat daarop geen eigendomsrechten rusten. We garanderen dan ook niet
            dat u in staat zult zijn om de door u gedane Gebruikersberichten
            achteraf te bewerken of te verwijderen.
          </Text3>
          <Text3 as="p">
            Wanneer u een Gebruikersbericht inzendt, belooft u ons dat:
          </Text3>
          <Text3 as="p">
            <ul>
              <li>
                u eigenaar bent van alle rechten over uw Gebruikersbericht (met
                inbegrip van, onder andere, alle betrokken reproductierechten en
                publicatierechten) of dat u alle noodzakelijke rechten voor uw
                Gebruikersberichten heeft verworven om u in staat te stellen om
                de betreffende rechten aan ons over te dragen, zoals beschreven
                in deze Voorwaarden;
              </li>
              <li>
                u heeft gezorgd en zal ook in de toekomst zorgen voor de
                betaling van alle licentiekosten, douanekosten en andere
                financiële verplichtingen van welke aard dan ook die
                voortvloeien uit het gebruik en de exploitatie van uw
                Gebruikersberichten;
              </li>
              <li>
                uw Gebruikersberichten niet leiden tot inbreuk op
                auteursrechten, handelsmerken, octrooien, handelsgeheimen of
                andere intellectuele eigendomsrechten, privacyrechten of
                wettelijke of morele rechten van derden;
              </li>
              <li>
                u er vrijwillig mee instemt om afstand te doen van alle "morele
                rechten" die u heeft over uw Gebruikersberichten;
              </li>
              <li>
                er, voor zover u bekend is, in uw Gebruikersberichten geen
                sprake is van valse, onjuiste of misleidende informatie;
              </li>
              <li>
                uw Gebruikersberichten niet in strijd zijn met de wet (met
                inbegrip van, onder andere, de wetten inzake exportcontrole,
                consumentenbescherming, oneerlijke concurrentie,
                antidiscriminatiebeleid of valse reclame);
              </li>
              <li>
                uw Gebruikersberichten niet lasterlijk, smadelijk, bedreigend,
                tendentieus of beledigend zijn uit racistisch, etnisch of
                religieus oogpunt, geen onwettige bedreiging of dwang vormen
                voor enige persoon, vereniging of genootschap en niet vulgair,
                pornografisch, obsceen of opdringerig zijn voor de privacy van
                anderen en in redelijkheid als zodanig ook niet kunnen worden
                beschouwd;
              </li>
              <li>
                u op geen enkel moment enige vorm van vergoeding of waardering
                ontvangt van derden voor het inzenden van uw
                Gebruikersberichten;
              </li>
              <li>
                uw Gebruikersberichten geen materiaal bevatten van websites van
                derden, noch postadressen, e-mailadressen, contactgegevens of
                telefoonnummers (anders dan die van uzelf);
              </li>
              <li>
                uw Gebruikersberichten geen virussen, wormen, spyware, adware of
                andere potentieel schadelijke programma's of bestanden bevatten;
              </li>
              <li>
                uw Gebruikersberichten geen vertrouwelijke, door
                eigendomsrechten beschermde of persoonlijke informatie bevatten;
                en
              </li>
              <li>
                uw Gebruikersberichten geen ongevraagde of ongeoorloofde reclame
                bevatten of vormen, noch promotiemateriaal, junkmail, spam,
                kettingbrieven, piramideschema's of enige andere vorm van
                oproeping.
              </li>
            </ul>
            Met het inzenden van een Gebruikersbericht verleent u ons een
            onherroepelijke, eeuwigdurende, overdraagbare, niet-exclusieve,
            volledig betaalde, internationale en royaltyvrije (op meerdere
            niveaus sublicentieerbare) licentie voor:
          </Text3>
          <Text3 as="p">
            <ul>
              <li>
                gebruik, distributie, reproductie, wijziging, aanpassing,
                publicatie, vertaling, openbare uitvoering en openbare weergave
                van uw gehele of gedeeltelijke Gebruikersberichten (of elke
                mogelijke wijziging daarvan), in welke vorm of op welke heden
                bekende of in de toekomst te ontwikkelen drager dan ook;
              </li>
              <li>
                gebruik (en toestemming tot gebruik aan anderen) van uw
                Gebruikersberichten op welke manier en voor welk doel dan ook
                (met inbegrip van, maar niet beperkt tot commerciële
                doeleinden), die wij geheel naar eigen goeddunken passend achten
                (met inbegrip van, onder andere, de integratie van uw
                Gebruikersberichten of een wijziging daarvan, in zijn geheel of
                gedeeltelijk, in elke mogelijke vorm van technologie, product of
                dienst);
              </li>
              <li>
                weergave van advertenties met betrekking tot uw
                Gebruikersberichten en gebruik van uw Gebruikersberichten voor
                reclame- en promotiedoeleinden.
              </li>
            </ul>
            Wij tolereren op onze website geen schadelijke of beledigende
            Gebruikersberichten. Hoewel daartoe niet verplicht, kunnen wij een
            voorselectie maken van de Gebruikersberichten, en controle
            uitoefenen op elke ruimte op deze Website vanaf waar
            Gebruikersberichten kunnen worden toegezonden. Wij zijn niet
            verplicht tot het hosten, publiceren of distribueren van enige
            Gebruikersbericht op of via deze Website, en kunnen te allen tijde
            een Gebruikersbericht om welke reden dan ook verwijderen of
            afwijzen. Wij zijn niet verantwoordelijk voor verlies, diefstal of
            beschadiging van welke aard dan ook van Gebruikersberichten. U stemt
            er verder mee in dat wij uw Gebruikersberichten vrijelijk kunnen
            verspreiden onder derden, zonder enige verplichting tot
            vertrouwelijkheid van de zijde van de ontvanger.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">ONGEOORLOOFDE ACTIVITEITEN</Text2>
          <Text3 as="p">
            Om elke twijfel te voorkomen, geven wij u uitsluitend toestemming om
            deze website te gebruiken voor de Toegestane Doeleinden. Elk andere
            activiteit op deze Website die verder gaat dan de Toegestane
            Doeleinden is verboden en betekent een ongeautoriseerd gebruik van
            de site. Dit is omdat, wat u en ons betreft, alle rechten op deze
            Website bij ons berusten.
          </Text3>
          <Text3 as="p">
            Ongeautoriseerd gebruik van deze Website kan leiden tot een
            schending van internationaal recht en van diverse staatswetten van
            de Verenigde Staten van Amerika op het gebied van intellectueel
            eigendom. Omdat we onze relatie zo gezond mogelijk willen houden,
            geven we hier enkele voorbeelden van dingen die u dient te
            vermijden. Tenzij u toestemming van ons heeft in andere zin, mag u
            deze Website niet gebruiken (het volgende betreft slechts
            voorbeelden en biedt geen uitputtende lijst van alles wat u niet mag
            doen):
          </Text3>
          <Text3 as="p">
            <ul>
              <li>
                voor enig openbaar of commercieel doel dat het gebruik van deze
                website op een andere site of in een andere netwerkomgeving
                omvat;
              </li>
              <li>
                op een zodanige manier dat er iets van deze Website wordt
                gewijzigd, openbaar weergegeven, openbaar uitgevoerd of
                verspreidt;
              </li>
              <li>
                op een wijze die in strijd is met mogelijke voorschriften,
                reglementen, verordeningen of verdragen, lokale of nationale
                wetgeving, staatswetgeving of buitenlandse of internationale
                wetgeving;
              </li>
              <li>
                om een andere persoon te belagen, lastig te vallen of schade toe
                te brengen;
              </li>
              <li>
                om u voor te doen als een andere persoon of entiteit of op een
                andere manier een verkeerde voorstelling te geven van uw band
                met een persoon of entiteit;
              </li>
              <li>
                om deze website of de servers of netwerken die ermee verbonden
                zijn, te verstoren of te wijzigen;
              </li>
              <li>
                om gebruik te maken van datamining, robots of soortgelijke
                methoden voor het verzamelen of verkrijgen van gegevens met
                betrekking tot deze Website; of
              </li>
              <li>
                te pogen om ongeautoriseerde toegang te krijgen tot enig
                onderdeel van deze Website of elke mogelijke andere account of
                ander computersysteem of netwerk verbonden met deze Website,
                hetzij door hacken of password-mining, hetzij op andere wijze.
              </li>
            </ul>
            U stemt in met het inhuren van advocaten voor onze verdediging als u
            deze voorwaarden schendt en de schending een probleem voor ons
            oplevert. U accepteert tevens dat u de kosten zult dragen van elke
            mogelijke schade die wij zouden moeten betalen als gevolg van uw
            overtredingen. U bent uitsluitend zelf verantwoordelijk voor
            eventuele door u begane schendingen van deze Voorwaarden. Wij
            behouden ons het recht voor om de verdediging en exclusieve controle
            op ons te nemen van elke mogelijke zaak die in aanmerking komt voor
            schadeloosstelling door u, en in een dergelijk geval, gaat u ermee
            akkoord om samen te werken met onze verdediging tegen een zodanige
            vordering.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">EIGENDOMSRECHTEN</Text2>
          <Text3 as="p">
            "Ironhack” is een gedeponeerd handelsmerk waarvan wij eigenaar zijn.
            Andere handelsmerken, namen en logo's op deze website zijn eigendom
            van hun respectieve eigenaars.
          </Text3>
          <Text3 as="p">
            Tenzij anders vermeld in deze Voorwaarden, zijn alle Materialen, met
            inbegrip van de beschikbaarstelling ervan op deze Website, onze
            exclusieve eigendom - Copyright © 2018. Alle rechten die in dit
            document niet uitdrukkelijk worden gegarandeerd, zijn voorbehouden.
            Tenzij door de toepasselijke wetgeving anders bepaald, is elke
            reproductie, distributie, wijziging, overdracht of publicatie van
            auteursrechtelijk beschermd materiaal, zonder de uitdrukkelijke,
            schriftelijke toestemming van de eigenaar van het auteursrecht of de
            licentie daarop, verboden.
          </Text3>
          <Text3 as="p">
            Voordat u een handelsmerk, naam, logo of content in welke vorm dan
            ook gebruikt of reproduceert, dient u contact met ons op te nemen
            voor toestemming. Het niet hebben van een dergelijke toestemming
            leidt tot schending van de internationale wetgeving waaronder de
            auteur of eigenaar van het handelsmerk, de naam of het logo of elke
            mogelijke content, beschermd is, en zal u blootstellen aan
            specifieke economische of strafrechtelijke sancties.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">SCHENDING VAN INTELLECTUELEEIGENDOMSRECHTEN</Text2>
          <Text3 as="p">
            Wij respecteren de intellectuele eigendomsrechten van anderen en
            verwachten van u dat u hetzelfde doet. Het is dan ook ons beleid om
            alle Gebruikersberichten die inbreuk maken op de intellectuele
            eigendomsrechten van anderen te verwijderen, en de toegang tot deze
            Website (of een onderdeel daarvan) op te schorten voor elke
            gebruiker die deze Website aanwendt in strijd met de intellectuele
            eigendomsrechten van anderen, of, wanneer de omstandigheden dit
            vereisen, door het opschorten van Ironhack Bootcamp voor elke
            gebruiker die deze Website gebruikt in strijd met de intellectuele
            eigendomsrechten van anderen. Op grond van Titel 17 van de United
            States Code hebben wij procedures ingesteld om schriftelijke
            kennisgevingen te ontvangen van claims wegens inbreuk op
            intellectueel eigendom en deze claims te verwerken in
            overeenstemming met die wet. Als u van mening bent dat uw
            auteursrecht of andere intellectuele eigendomsrechten zijn
            geschonden door een gebruiker van deze Website, kunt u daarvan
            schriftelijk kennisgeving doen aan Ironhack:
          </Text3>
          <Text3 as="p">T.a.v. Ironhack DMCA Agent</Text3>
          <Text3 as="p">
            E-mailadres:&nbsp;
            <a href="mailto: legal@ironhack.com">legal@ironhack.com</a>
          </Text3>
          <Text3 as="p">
            Om ervoor te zorgen dat de kwestie onmiddellijk wordt behandeld,
            moet de schriftelijk kennisgeving:
          </Text3>
          <Text3 as="p">
            <ul>
              <li>
                met uw fysieke of elektronische handtekening zijn getekend;
              </li>
              <li>
                het auteursrechtelijk beschermde werk of ander intellectueel
                eigendom waarop vermeend inbreuk is gemaakt aanduiden;
              </li>
              <li>
                het vermeend geschonden materiaal voldoende gedetailleerd
                identificeren om ons in staat te stellen dat materiaal te
                lokaliseren;
              </li>
              <li>
                de nodige informatie bevatten zodat wij contact met u kunnen
                opnemen (met inbegrip van uw postadres, telefoonnummer en
                e-mailadres);
              </li>
              <li>
                een verklaring bevatten dat u te goeder trouw gelooft dat het
                gebruik van het materiaal dat is beschermd door auteursrechten
                of andere intellectuele eigendomsrechten niet is toegestaan door
                de eigenaar, de agent van de eigenaar of de wet;
              </li>
              <li>
                een verklaring bevatten dat de informatie in de schriftelijke
                kennisgeving juist is; en
              </li>
              <li>
                een verklaring bevatten, op straffe van meineed, dat u bevoegd
                bent om op te treden namens de eigenaar van het auteursrecht of
                enig ander intellectueel eigendomsrecht.
              </li>
            </ul>
            Indien de kennisgeving niet verwijst naar een inbreuk op
            auteursrechten of andere intellectuele eigendomsrechten, kan de
            DMCA-agent van Ironhack de betreffende kwestie niet in behandeling
            nemen.
          </Text3>
          <Text3 as="p">Verzending van een tegenbericht van DMCA </Text3>
          <Text3 as="p">
            Ingeval we de toegang tot door u aangeleverd, auteursrechtelijk
            beschermd materiaal blokkeren of beperken, zullen we u op de hoogte
            stellen als een dergelijke blokkering onderworpen is aan de geldige
            ontvangst van een kennisgeving van verwijdering, zoals bepaald is in
            de Digital Millennium Copyright Act (DMCA). In antwoord daarop kunt
            u de DMCA-agent van Ironhack een schriftelijk tegenbericht sturen
            dat het volgende dient te bevatten:
          </Text3>
          <Text3 as="p">
            <ol>
              <li>uw fysieke of elektronische handtekening;</li>
              <li>
                identificatie van het materiaal dat werd verwijderd of waarvan
                de toegang werd beperkt, en de plaats waar het materiaal vóór de
                verwijdering of toegangsbeperking kon worden gevonden;
              </li>
              <li>
                een verklaring op straffe van meineed dat u te goeder trouw
                gelooft dat het materiaal is verwijderd of dat de toegang tot
                het materiaal is beperkt als gevolg van een vergissing of fout
                bij het identificeren van materiaal dat zou moeten worden
                verwijderd of waarvan de toegang zou moeten worden
                beperkt;&nbsp;
              </li>
              <li>
                uw naam, postadres en telefoonnummer, en een verklaring dat u de
                jurisdictie accepteert van een federale rechtbank van het
                district van uw postadres, of, indien uw postadres buiten de
                Verenigde Staten is, van een gerechtelijk arrondissement waar
                wij een vestiging hebben, en dat u de dagvaarding zult
                accepteren van de persoon die de kennisgeving heeft gedaan van
                het vermeende inbreuk makende materiaal, of van diens
                vertegenwoordiger.&nbsp;
              </li>
            </ol>
            Schorsing van recidiverende gebruikers
          </Text3>
          <Text3 as="p">
            Wij behouden ons het recht voor om, naar eigen goeddunken, een
            Ironhack Bootcamp of de toegang tot deze Website of Service op te
            schorten voor elke gebruiker die meerder malen een melding heeft
            gekregen van de DMCA of meerdere malen inbreuk heeft gepleegd.
            Berichten en tegenberichten dienen te voldoen aan de op elk moment
            geldende wettelijke eisen van de DMCA; voor meer informatie, zie
            https://www.copyright.gov/. Raadpleeg, voordat u een kennisgeving of
            tegenbericht indient, uw juridisch adviseur en lees artikel 512 van
            titel 17 van de United States Code, aangezien er onder de DMCA
            sancties zijn voor valse claims.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">UITSLUITING VAN GARANTIE</Text2>
          <Text3 as="p">
            DEZE WEBSITE WORDT AANGEBODEN "IN DE STAAT WAARIN DEZE ZICH
            BEVINDT", "MET ALLE MOGELIJKE FOUTEN" EN U AANVAARDT ALLE RISICO'S
            MET BETREKKING TOT DE KWALITEIT EN PRESTATIES ERVAN.
          </Text3>
          <Text3 as="p">
            WIJ BELOVEN NIET DAT DE WEBSITE OF DE CONTENT, DE SERVICE OF DE
            FUNCTIES ERVAN ONONDERBROKEN, TIJDIG, VEILIG OF FOUTLOOS ZAL KUNNEN
            WORDEN AANGEBODEN, NOCH DAT GEBREKEN ZULLEN WORDEN GECORRIGEERD.
          </Text3>
          <Text3 as="p">
            DE WEBSITE EN DE INHOUD ERVAN WORDT AANGEBODEN "IN DE STAAT WAARIN
            DEZE ZICH BEVINDT" EN "NAAR GELANG BESCHIKBAARHEID”.
          </Text3>
          <Text3 as="p">
            WIJ KUNNEN NIET GARANDEREN DAT DE BESTANDEN OF GEGEVENS DIE U VAN
            DEZE SITE DOWNLOADT, VRIJ ZIJN VAN VIRUSSEN OF VORMEN VAN
            BESMETTING, OF VAN DESTRUCTIEVE ELEMENTEN.
          </Text3>
          <Text3 as="p">
            WIJ GEVEN GEEN ENKELE GARANTIE VAN WELKE AARD DAN OOK, NOCH
            UITDRUKKELIJK, NOCH IMPLICIET, NOCH WETTELIJK, MET INBEGRIP VAN,
            ONDER ANDERE, DE IMPLICIETE GARANTIES VAN VERKOOPBAARHEID,
            GESCHIKTHEID VOOR EEN BEPAALD DOEL OF GEBRUIK, OF EIGENDOM EN
            NIET-INBREUK OP INTELLECTUELE EIGENDOMSRECHTEN.
          </Text3>
          <Text3 as="p">
            WIJ WIJZEN OOK ELKE VERANTWOORDELIJKHEID AF VOOR HET HANDELEN,
            NALATEN EN GEDRAG VAN DERDEN IN SAMENHANG OF VERBAND MET HET GEBRUIK
            DOOR U VAN DE WEBSITE OF DE DIENSTEN VAN IRONHACK.
          </Text3>
          <Text3 as="p">
            DIT BETEKENT DAT WE NIET KUNNEN BELOVEN DAT DE WEBSITE VOLLEDIG
            PROBLEEMLOOS ZAL ZIJN.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">RESULTATEN</Text2>
          <Text3 as="p">
            Wij geven geen garanties met betrekking tot de resultaten die kunnen
            worden verkregen uit het gebruik van deze website of de
            nauwkeurigheid of betrouwbaarheid van de via deze site verkregen
            informatie. Geen enkel advies, noch enige informatie, hetzij in
            mondelinge of schriftelijke vorm, die u van deze Website of van ons,
            onze filialen of verbonden ondernemingen heeft verkregen, geeft
            enige garantie. Wij verlenen geen enkele vorm van billijke
            compensatie.
          </Text3>
          <Text3 as="p">
            Wij doen er alles aan om de beste instructeurs en medewerkers in te
            huren om u de best mogelijke Ironhack Bootcamp ervaring te bieden.
            Wij kunnen in onze reclame een specifieke Ironhack Bootcamp
            instructeur presenteren, maar dat betekent niet dat wij die
            instructeur specifiek aanbevelen en er is geen garantie dat de
            cursus waarvoor u zich inschrijft door die instructeur zal worden
            geleid. Wij doen geen uitspraken over de kwaliteit of de aard van
            een Ironhack Bootcamp instructeur.
          </Text3>
          <Text3 as="p">
            Wij kunnen van tijd tot tijd in onze reclames op deze Website, op
            sociale media of op projectcentra de resultaten van bepaalde
            Ironhack Bootcamp studenten presenteren. Ironhack garandeert in geen
            geval resultaat, werk, salaris of beroepskansen. Ironhack Bootcamp
            is geen programma voor herintreding op de arbeidsmarkt en bepaalde
            resultaten zullen niet reproduceerbaar zijn. We streven ernaar om
            absoluut transparant te zijn met de gepresenteerde getuigenissen van
            Ironhack studenten en resultaten uit het verleden, inclusief
            gemiddelde scores en uitzonderingen. Ons doel is om Ironhack
            Bootcamp-deelnemers de nodige informatie te verschaffen om te kunnen
            begrijpen dat prestaties uit het verleden geen indicator zijn voor
            prestaties in de toekomst.
          </Text3>
          <Text3 as="p">
            HET FEIT DAT EEN IRONHACK-LEERLING EEN GOEDE BAAN HEEFT GEKREGEN NA
            HET VOLTOOIEN VAN EEN IRONHACK BOOTCAMP GARANDEERT NIET DAT U
            HETZELFDE RESULTAAT ZULT BEHALEN. DE RESULTATEN ZIJN ZEER VARIABEL
            EN AFHANKELIJK VAN DE LOCATIE EN DE MARKTOMSTANDIGHEDEN, IETS WAAROP
            WIJ GEEN INVLOED HEBBEN. WIJ GARANDEREN GEEN ENKEL RESULTAAT.
          </Text3>
          <Text3 as="p">
            Het afronden van een Ironhack Bootcamp garandeert niet dat u een
            expert wordt op het gebied van de leerstof. Onze instructeurs en
            medewerkers werken eraan om via het Ironhack Bootcamp het leerproces
            te sturen, maar sommige studenten eindigen niet met een bevredigend
            resultaat. Sommige studenten die afstuderen aan Ironhack Bootcamp
            vinden geen baan in hun vakgebied. Sommige studenten maken de studie
            niet af, en sommigen verwerven niet de vaardigheden en kennis van
            het vak dat op Ironhack Bootcamp werd aangeboden. Voordat u zich
            inschrijft voor een Ironhack Bootcamp, is het aan te raden om met
            een Ironhack Programleider te praten over de verschillende locaties
            en cursusduren, toelatingseisen, studiekosten en bijbehorende kosten
            en over uw eigen capaciteiten.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">AANSPRAKELIJKHEIDSBEPERKING</Text2>
          <Text3 as="p">
            WIJ AANVAARDEN GEEN AANSPRAKELIJKHEID VOOR SCHADE DIE VOORTVLOEIT
            UIT HET BEKIJKEN, KOPIËREN OF DOWNLOADEN VAN MATERIAAL VAN DEZE
            WEBSITE, VOOR ZOVER TOEGESTAAN DOOR DE TOEPASSELIJKE WETGEVING. IN
            GEEN GEVAL ZIJN WIJ AANSPRAKELIJK VOOR INDIRECTE, BUITENGEWONE,
            EXEMPLAIRE, PUNITIEVE, BIJZONDERE, INCIDENTELE SCHADE OF
            GEVOLGSCHADE (MET INBEGRIP VAN VERLIES VAN GEGEVENS, INKOMSTEN,
            WINST, GEBRUIK OF ANDERE ECONOMISCHE VOORDELEN), ONGEACHT DE
            OORZAAK, ZELFS AL ZIJN WIJ BEKEND MET HET RISICO VAN DERGELIJKE
            SCHADE.
          </Text3>
          <Text3 as="p">
            Sommige rechtssystemen staan een aansprakelijkheidsbeperking niet
            toe, in welk geval het bovenstaande niet van toepassing zal zijn.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">SCHADEVERGOEDING</Text2>
          <Text3 as="p">
            U stemt ermee in dat Ironhack, diens directeuren, bestuurders,
            aandeelhouders, werknemers, agenten, filialen en verbonden
            ondernemingen schadeloos gesteld worden en gevrijwaard blijven van
            alle claims, vorderingen, verliezen, aansprakelijkheden of onkosten
            (met inbegrip van advocatenhonoraria), ingesteld of gevorderd door
            derden als gevolg van of in verband met het gebruik van deze
            website.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">LOKALE WETGEVING EXPORTCONTROLE</Text2>
          <Text3 as="p">
            Wij controleren en beheren deze Website vanuit ons hoofdkantoor in
            de Verenigde Staten van Amerika, en het kan zijn dat delen van deze
            Website niet geschikt zijn voor, of niet beschikbaar zijn in andere
            delen van de wereld. Als u deze website buiten de Verenigde Staten
            van Amerika gebruikt, is het uw verantwoordelijkheid om de
            toepasselijke wetgeving na te leven.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">
            OPLOSSING VAN GESCHILLEN EN ARBITRAGE AFZIEN VAN COLLECTIEVE
            VORDERINGEN
          </Text2>
          <Text3 as="p">
            Leest u deze bepaling aandachtig door. Deze betreft uw wettelijke
            rechten.
          </Text3>
          <Text3 as="p">
            Deze bepaling betreffende “Oplossing van geschillen en Arbitrage;
            afzien van collectieve vorderingen” (de "<strong>Bepaling</strong>")
            staat een snelle en effectieve behandeling toe van mogelijke
            geschillen die kunnen ontstaan tussen u en ons; bijvoorbeeld,
            vorderingen of geschillen over contracten, wetten, voorschriften,
            verordeningen of delicten (zoals, onder andere, bedrog, valsheid,
            frauduleuze winsten of nalatigheid), of over elke mogelijke theorie
            in rechte of billijkheid, met inbegrip van de geldigheid,
            afdwingbaarheid of reikwijdte van deze bepaling (met uitzondering
            van de toepasbaarheid van de onderstaande clausule van afstand van
            collectieve vorderingen). Daarom moet het begrip "geschil" zo ruim
            mogelijk worden genomen als onder de wet mogelijk is; het omvat alle
            vorderingen in verband met de diensten of producten die u worden
            aangeboden of gefactureerd, tegen andere partijen (zoals
            licentiehouders, leveranciers, distributeurs of externe verkopers),
            voor zover u in dezelfde procedure ook tegen ons een vordering
            instelt.
          </Text3>
          <Text3 as="p">
            Deze bepaling bepaalt dat alle geschillen tussen u en ons moeten
            worden beslecht door middel van bindende arbitrage, omdat
            aanvaarding van deze voorwaarden betekent dat u afziet van uw recht
            op een gerechtelijke vordering en de mogelijkheid om te worden
            gehoord door een rechter of jury. Wij geven hier de voorkeur aan,
            omdat wij van mening zijn dat arbitrage effectiever en minder
            controversieel is dan een rechtszaak. Voor alle duidelijkheid, in
            een arbitrage zijn er geen rechters of jury's en herziening door een
            rechtbank is beperkt. De arbiter dient zich te laten leiden door
            deze overeenkomst en heeft de bevoegdheid om dezelfde
            schadevergoedingen en rechtsmiddelen vast te stellen als een
            rechtbank (met inbegrip van de honoraria van advocaten). U kunt
            echter afstand doen van deze bepaling, wat u het recht of de
            mogelijkheid geeft om een vordering in te stellen voor de rechter of
            jury, of om deel te nemen aan of zich te laten vertegenwoordigen in
            een rechtszaak die door anderen wordt aangespannen (met inbegrip van
            maar niet beperkt tot collectieve vorderingen). U EN WIJ KOMEN
            OVEREEN DAT, MET UITZONDERING VAN HET HIERONDER BEPAALDE, ELK
            GESCHIL, ZOALS HIERBOVEN GEDEFINIEERD, OF HET NU ACTUEEL IS OF
            GEBASEERD IS OP VROEGERE OF TOEKOMSTIGE HANDELINGEN OF NALATIGHEDEN,
            UITSLUITEND EN DEFINITIEF ZAL WORDEN BESLECHT DOOR BINDENDE
            ARBITRAGE IN PLAATS VAN DOOR DE RECHTER, IN OVEREENSTEMMING MET DEZE
            BEPALING.
          </Text3>
          <Text3 as="p">
            Beslissing over een claim voorafgaande aan de Arbitrage
          </Text3>
          <Text3 as="p">
            Voor alle geschillen, of ze nu zullen worden behandeld door de
            rechter of door arbitrage, moet u ons eerst in de gelegenheid
            stellen een oplossing te zoeken. De eerste stap is om ons een e-mail
            te sturen naar het volgende adres&nbsp;
            <a href="mailto:legal@ironhack.com">legal@ironhack.com</a>, met de
            volgende informatie: (1) uw naam, (2) uw adres, (3) een
            schriftelijke beschrijving van de claim en de grond van het geschil,
            en (4) een beschrijving van de specifieke oplossing die u verwacht.
            Als wij voor uw geschil niet binnen 45 dagen na ontvangst van uw
            kennisgeving een oplossing bieden, kunt u het voorleggen aan
            arbitrage. Het geschil kan alleen worden voorgelegd aan de rechter
            onder de hierna beschreven omstandigheden.
          </Text3>
          <Text3 as="p">Uitsluitingen van Arbitrage / Uitsluitingsrecht </Text3>
          <Text3 as="p">
            Niettegenstaande het voorgaande kunt u of kunnen wij besluiten om
            een geschil te laten oplossen door de rechter in plaats van door
            arbitrage als: (a) het geschil voldoet aan de vereisten om te kunnen
            worden beschouwd als vordering van minder belang; of als (b) U
            ZICHZELF BINNEN 30 DAGEN NA DATUM VAN AANVAARDING VAN DEZE
            OVEREENKOMST UITSLUIT VAN DEZE ARBITRAGEBEPALING (de
            <strong> “Termijn voor het Uitsluitingsrecht</strong>"). U kunt zich
            van deze arbitragebepaling uitsluiten door een e-mail te sturen
            naar&nbsp;
            <a href="mailto: legal@ironhack.com">legal@ironhack.com</a> met de
            volgende informatie: 1) uw naam, (2) uw adres, (3) een duidelijke
            verklaring dat u geen geschillen met ons wilt oplossen door middel
            van arbitrage. Wij zullen uw beslissing niet persoonlijk nemen en we
            beloven u dat de beslissing om zich uit te sluiten van deze
            arbitragebepaling geen negatieve gevolgen zal hebben voor uw relatie
            met ons. Maar wel moet de termijn voor het Uitsluitingsrecht worden
            gerespecteerd; houd er dus rekening mee dat&nbsp;
            <strong>
              elk verzoek om uitsluiting dat wordt ontvangen na de gestelde
              termijn niet in aanmerking kan worden genomen en dat u uw
              geschillen zult moeten beslechten door arbitrage of door de
              rechter die bevoegd is voor zaken van gering belang.
            </strong>
          </Text3>
          <Text3 as="p">De Arbitrageprocedure</Text3>
          <Text3 as="p">
            Als deze bepaling van toepassing is en het geschil niet wordt
            opgelost als hierboven beschreven (Beslissing over een claim
            voorafgaande aan de Arbitrage), kunnen zowel u als wij een
            arbitrageprocedure starten. De American Arbitration
            Association&nbsp;
            <strong>(AAA</strong>), www.adr.org, of JAMS, www.jamsadr.com,
            zullen bemiddelen voor de arbitrage in alle geschillen; geschillen
            zullen altijd voor één enkele arbiter worden gebracht. Arbitrage zal
            individueel worden ingeleid en nooit plaats vinden in de vorm van
            collectieve arbitrage of van een representatieve of geconsolideerde
            vordering of arbitrage. De arbiter beslist over alle zaken, ook over
            de reikwijdte van deze arbitragebepaling.
          </Text3>
          <Text3 as="p">
            In het geval van AAA-arbitrage zullen, als de geschillen een belang
            vertegenwoordigen van minder dan $75.000, de Supplementary
            Procedures for Consumer-Related Disputes (aanvullende procedures
            voor consumentengeschillen) van toepassing zijn; bij geschillen van
            $75.000 of meer, zijn de Commercial Arbitration Rules (commerciële
            arbitrageregels) van de AAA van toepassing. In beide gevallen zijn
            de Optional Rules For Emergency Measures Of Protection (optionele
            regels voor noodmaatregelen van bescherming) van toepassing. De
            reglementen van de AAA zijn beschikbaar op www.adr.org of via
            telefoonnummer 1-800-778-7879. Bij arbitrage door JAMS zijn het
            Comprehensive Arbitration Rules &amp; Procedures (algemeen reglement
            betreffende arbitrage en procedures) en de Recommended Arbitration
            Discovery Protocols For Domestic, Commercial Cases (aanbevolen
            arbitrageprotocollen voor binnenlandse, commerciële zaken) van
            toepassing. Het reglement van JAMS is beschikbaar op www.jamsadr.com
            of 1-800-352-5267. Deze Bepaling zal gelden in geval van
            strijdigheid van de toepasselijke arbitrageregels. De arbitrage zal
            in geen geval gehouden zijn aan de reglementen of procedures van de
            representatieve of collectieve vordering.
          </Text3>
          <Text3 as="p">
            Omdat deze Website en deze Voorwaarden van toepassing zijn op de
            handel tussen staten, is het hier de Federal Arbitration Act
            (federale arbitragewet - "<strong>FAA</strong>") die de arbitrage
            van alle geschillen regelt. De arbiter zal echter, uit hoofde van de
            FAA, naar eigen inzicht het relevante materiële recht en de
            toepasselijke verjaringstermijn of opschortende voorwaarde
            toepassen.
          </Text3>
          <Text3 as="p">
            <em>Arbitrale uitspraak</em> - De arbiter kan op individuele basis
            elke vergoeding toekennen die mogelijk is onder de toepasselijke
            wetgeving, doch is niet bevoegd om zich uit te spreken over
            vergoedingen ten koste of ten voordele van een persoon die geen
            partij is in de procedure. De arbiter doet schriftelijk kennisgeving
            van zijn beslissing, maar hoeft deze niet met redenen te omkleden,
            tenzij daartoe door een partij verzocht of door het toepasselijke
            recht verplicht. De beslissing is definitief, heeft kracht van
            gewijsde en is bindend voor de partijen, met uitzondering van de
            door de FAA of ander toepasselijk recht voorziene middelen van
            beroep, en kan worden voorgelegd aan elke rechtbank die bevoegd is
            voor de partijen met het oog op de tenuitvoerlegging.
          </Text3>
          <Text3 as="p">
            <em>Plaats van arbitrage</em> - U of wij kunnen zowel in Florida als
            in het federale gerechtelijk arrondissement dat overeenkomt met uw
            factuuradres een arbitrageprocedure starten.
          </Text3>
          <Text3 as="p">
            <em>Betaling van de tarieven en onkosten van arbitrage </em>- Indien
            u dit vooraf, schriftelijk heeft verzocht, betalen wij alle kosten
            voor het inleiden van de arbitrageprocedure en de kosten voor de
            hoorzittingen van de AAA of JAMS, alsmede de kosten, uitgaven en
            honoraria van de arbiter, indien deze laatste daartoe voorafgaand
            aan of tijdens de eerste hoorzitting voor de bewijsvoering in de
            arbitrage schriftelijk heeft verzocht. U bent echter wel zelf
            verantwoordelijk voor alle extra kosten die u tijdens de arbitrage
            maakt, met inbegrip van, onder andere, de honoraria van advocaten of
            deskundigen. Als u ons daarvan kennisgeving doet en daarover met ons
            te goeder trouw onderhandelt zoals uiteengezet in de bovenstaande
            paragraaf: "Beslissing over een claim voorafgaand aan de arbitrage"
            en de arbiter bepaalt dat u in de procedure de winnende partij bent,
            heeft u, naast de honoraria en kosten die op grond van het
            toepasselijk recht kunnen worden teruggevorderd, ook recht op
            uitkering van redelijke honoraria en onkosten van advocaten als vast
            te stellen door de arbiter.&nbsp;
          </Text3>
          <Text3 as="p">Afzien van Collectieve Vorderingen</Text3>
          <Text3 as="p">
            Tenzij in deze arbitragebepaling anders bepaald, is de arbiter niet
            bevoegd om claims van meer dan één persoon te consolideren en mag
            hij zich niet buigen over enige andere vorm van collectieve of
            representatieve procedures (zoals een collectieve vordering, een
            geconsolideerde vordering, een representatieve vordering of een
            particuliere vordering), tenzij door u en ons uitdrukkelijk,
            schriftelijk overeengekomen na het inleiden van de arbitrage. Als u
            besluit om het geschil voor de rechter te brengen nadat u zich,
            zoals hierboven vermeld, heeft uitgesloten van de toepassing van
            deze arbitragebepaling, dan is dit afzien van een collectieve
            vordering niet op u van toepassing. Noch u, noch andere gebruikers
            van deze Website mogen optreden als vertegenwoordigers van een
            collectief of leden van een collectief, noch anderszins deelnemen
            aan een collectieve, geconsolideerde of representatieve procedure,
            als niet eerder gebruik is gemaakt van het hierboven gespecificeerde
            Uitsluitingsrecht.
          </Text3>
          <Text3 as="p">Afzien van een jury</Text3>
          <Text3 as="p">
            U begrijpt en gaat ermee akkoord dat u en wij, door het aanvaarden
            van de arbitragebepaling van deze Voorwaarden, afzien van het recht
            op een juryrechtspraak of op een openbare behandeling van de zaak
            voor een rechter. Als deze arbitragebepaling niet bestaan zou
            hebben, dan hadden u en wij het recht of de mogelijkheid gehad om
            geschillen voor te leggen aan de rechter of jury, of om partij te
            zijn of vertegenwoordigd te worden in een zaak die door anderen voor
            de rechter aanhangig is gemaakt (met inbegrip van collectieve
            vorderingen). Tenzij hieronder anders aangegeven, komen deze rechten
            te vervallen. Andere rechten die u zou hebben gehad als u naar de
            rechter was gegaan (bijvoorbeeld het recht om in beroep te gaan of
            het recht op “discovery”) kunnen beperkt of zelfs vervallen zijn.
          </Text3>
          <Text3 as="p">Deelbaarheid</Text3>
          <Text3 as="p">
            Indien een clausule van deze Arbitragebepaling (anders dan de
            clausule van Afzien van Collectieve Vordering) onwettig of
            niet-toepasbaar wordt bevonden, zal deze bepaling uit deze
            Arbitragebepaling worden geschrapt en zal de rest van de
            Arbitragebepaling volledig geldig en toepasbaar blijven. Als de
            clausule van Afzien van Collectieve Vordering onwettig of
            niet-toepasbaar wordt geacht, zal de gehele Arbitrageclausule
            niet-toepasbaar zijn en zal het geschil worden beslecht door de
            rechter.
          </Text3>
          <Text3 as="p">Continuïteit</Text3>
          <Text3 as="p">
            Deze arbitragebepaling blijft van kracht ook nadat u uw Ironhack
            Bootcamp bij ons of bij een van onze filialen heeft afgerond en het
            gebruik van deze Website heeft gestaakt. Onverminderd elke
            mogelijke, andersluidende bepaling in deze Voorwaarden, accepteren
            wij dat u, indien wij deze arbitragebepaling op enigerlei wijze
            veranderen (behoudens in het geval van adreswijzigingen voor
            kennisgevingen), dergelijke veranderingen kunt afwijzen en van ons
            kunt verlangen dat wij ons in geval van geschil houden aan de
            bewoordingen van de huidige bepaling.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">TAAL</Text2>
          <Text3 as="p">
            De hier betrokken Partijen hebben uitdrukkelijk verlangd dat deze
            overeenkomst en alle documenten en kennisgevingen in verband hiermee
            worden opgesteld in de Engelse taal.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">ALGEMEEN</Text2>
          <Text3 as="p">
            Wij zijn van mening dat een directe communicatie de meeste problemen
            kan voorkomen (als wij denken dat u zich niet aan een bepaalde
            voorwaarde houdt, zullen we u dat laten weten). Omdat we onze
            relatie zeer op prijs stellen, zullen we u zelfs aanbevelingen doen
            voor de nodige corrigerende maatregelen.
          </Text3>
          <Text3 as="p">
            Het kan echter zijn dat naar ons oordeel bepaalde schendingen van
            deze Voorwaarden een onmiddellijke opschorting van uw toegang tot
            deze Website vereisen, zonder u daarvan voorafgaande kennisgeving te
            hebben gedaan. Ongeacht de keuze van wettelijke bepalingen of het
            toepasselijk conflictenrecht, worden deze Voorwaarden beheerst door
            de Federal Arbitration Act, de wetten van de staat Florida en de
            toepasselijke federale wetten van de Verenigde Staten. Buitenlandse
            wetten zullen niet van toepassing zijn. Met uitzondering van de
            hierboven beschreven geschillen die onderworpen zijn aan arbitrage,
            zal elk geschil met betrekking tot deze Voorwaarden of deze Website
            worden voorgelegd aan de rechter van Miami-Dade County, Florida.
            Indien een bepaling van deze Voorwaarden door een bevoegde rechter
            nietig, inconsistent of niet-afdwingbaar wordt bevonden, zal een
            dergelijke bepaling voor zover strikt noodzakelijk worden beperkt of
            geschrapt, en worden vervangen door een geldige bepaling, die de
            bedoelingen van deze Voorwaarden zo goed mogelijk weergeeft, zodat
            deze Voorwaarden volledig van kracht kunnen blijven, zonder
            wijziging van andere bepalingen. De niet-toepassing van deze
            voorwaarden betekent niet dat wij afstand doen van onze rechten.
            Deze voorwaarden vormen de volledige overeenkomst tussen u en ons en
            komen in de plaats van alle voorafgaande of lopende
            onderhandelingen, discussies of overeenkomsten met wie dan ook met
            betrekking tot deze Website. Intellectuele eigendomsrechten, het
            afzien van garanties, uw verklaringen, schadeloosstellingen,
            aansprakelijkheidsbeperkingen en algemene bepalingen blijven ook na
            afloop van deze Voorwaarden van kracht.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">KENNISGEVING AAN DE CONSUMENT IN CALIFORNIË</Text2>
          <Text3 as="p">
            In overeenstemming met sectie 1789.3 van het Burgerlijk Wetboek van
            Californië hebben gebruikers recht op de volgende kennisgeving van
            consumentenrechten: De aanbieder van deze Website en van de Diensten
            is Ironhack, 990 Biscayne Blvd, Ste. 502, Miami FL 33132. Als u
            vragen of klachten heeft over deze website of de dienst, kunt u
            rechtstreeks contact met ons opnemen via&nbsp;
            <a href="mailto: legal@ironhack.com">legal@ironhack.com</a> U kunt
            ook contact met ons opnemen door te schrijven naar Ironhack, 990
            Biscayne Blvd, Ste. 502, Miami FL 33132. Ingezetenen van Californië
            kunnen contact opnemen met de Complaint Assistance Unit van de
            Division of Consumer Services van het California Department of
            Consumer Affairs , hetzij per post naar 1625 North Market Blvd.,
            Sacramento, CA 95834, hetzij per telefoon op (916) 445-1254 of (800)
            952-5210; voor slechthorenden: TDD (800) 326-2297 of TDD (916)
            322-1700.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">OPMERKINGEN</Text2>
          <Text3 as="p">
            Alles wat u ons via elk mogelijk communicatiemiddel toestuurt (bijv.
            opmerkingen, getuigenissen, citaten, vragen, suggesties of
            materialen, gezamenlijk te noemen: <strong>"Comentaren</strong>")
            van welke aard dan ook (bijv. oproepen, blogcommentaren, e-mails)
            zal worden beschouwd als niet-vertrouwelijk en van niet exclusieve
            eigendom. Tenzij anders bepaald door de wet, staat u hierbij alle
            rechten, aanspraken en belangen af, en wij kunnen vrijelijk en
            zonder beloning of tegenprestatie gebruik maken van alle ideeën,
            kennis, concepten, technieken of andere intellectuele eigendoms- of
            auteursrechten die in de commentaren zijn opgenomen, ongeacht of
            deze al dan niet patenteerbaar zijn, en voor elk mogelijk doel, met
            inbegrip van, onder andere, het ontwikkelen, produceren in het
            verleden, heden of in de toekomst, en het in licentie geven, op de
            markt brengen en rechtstreeks of indirect verkopen van producten en
            diensten met gebruikmaking van dergelijke commentaren. Voor in de
            gevallen waarin de bovenstaande overdracht bij wet verboden is,
            verleent u ons bij deze een exclusieve, overdraagbare,
            internationale, royaltyvrije, volledig betaalde licentie (met
            inbegrip van het recht om een sublicentie te verlenen) voor het
            gebruik en de exploitatie van alle commentaren zoals door ons naar
            eigen goeddunken te bepalen. Onverminderd het voorgaande, begrijpt u
            en gaat u ermee akkoord dat wij niet verplicht zijn dergelijke
            ideeën, kennis, concepten of technieken uit de commentaren te
            gebruiken, te publiceren, te reproduceren of te distribueren, en dat
            u niet gerechtigd bent om gebruik, publicatie, reproductie of
            distributie te eisen.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">CONTACT</Text2>
          <Text3 as="p">
            Indien u vragen heeft over deze Voorwaarden of om andere redenen
            contact met ons wilt opnemen, kunt u dit doen door een e-mail te
            sturen naar&nbsp;
            <a href="mailto: legal@ironhack.com">legal@ironhack.com</a>.
          </Text3>
        </Question>
      </Layout>
      <Footer page={pageName} />
    </>
  );
};

export const query = graphql`
  query {
    locales: allLocale(
      filter: {
        lng: { eq: "nl" }
        ns: { regex: "/(seo)|(breadcrumbs)|(menu)|(banner)/" }
      }
    ) {
      ...TranslationFragment
    }
  }
`;

export default withI18next('nl')(TermOfUse);
